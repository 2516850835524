import React, { useState, useEffect } from "react";
import { useNavigate, Redirect } from "react-router-dom";
import { Card, makeStyles, Typography, Avatar } from "@material-ui/core";
import customerDetails from "./images/imgHomepageShopOwner/customer details.png";
import orders from "./images/imgHomepageShopOwner/orders.png";
import ratesUpdater from "./images/imgHomepageShopOwner/rates updater.png";
import report from "./images/imgHomepageShopOwner/report.png";
import wave1 from "./images/imgHomepageShopOwner/wave1.svg";
import { useLocation } from "react-router-dom";
import Zoom from "@material-ui/core/Zoom";
import AppBarHead from './AppbarHead'
import Helpers from './Helpers'
import Footer from './Footer'
import axios from "axios";
import CountUp from "react-countup";
import { Colors, Fonts } from "./constants";
import store from "store2";
import swal from "sweetalert2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarWeek, faBoxes, faCheck, faSpinner, faShoppingBag, faCheckDouble, faFolder, faUsers, faUser, } from '@fortawesome/free-solid-svg-icons'


const bgColor = "#E5E5E5"

export default function HomePage(props) {
  const useStyles = makeStyles((theme) => ({
    appbar: {
      backgroundColor: "#00adb5",
    },
    smallsize: {
      [theme.breakpoints.down('sm')]: { fontSize: '25px' },
      [theme.breakpoints.up('md')]: { fontSize: '50px' }
    }
    ,
    appbarTypo: {
      flexGrow: "1",
      fontSize: "24px",
      fontWeight: "bold",
    },
    headingDiv: {
      display: "flex",
      justifyContent: "center",
    },
    headingWelcome: {
      marginTop: theme.spacing(4),
      fontWeight: "bold",
      fontSize: "30px",
      color: "#00adb5",
    },
    grid: {
      [theme.breakpoints.up("md")]: {
        marginLeft: theme.spacing(55),
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: theme.spacing(47),
      },
    },
    card: {
      [theme.breakpoints.up("md")]: {
        alignItems: "center",
        display: "inline-block",
        background: "white",
        width: "250px",
        height: "200px",
        paddingLeft: "10px",
        paddingRight: "10px",
        margin: "40px",
        cursor: "pointer",
      },
      [theme.breakpoints.down("sm")]: {
        alignItems: "center",
        display: "inline-block",
        background: "white",
        color: "black",
        fontWeight: "bold",
        width: "260px",
        height: "180px",
        paddingLeft: "10px",
        paddingRight: "10px",
        margin: "12px",
        cursor: "pointer",
      },
    },
    imgCard: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "10px",
        width: "90px",
        height: "85px",
        marginLeft: theme.spacing(10),
      },
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(5),
        width: "100px",
        height: "100px",
        marginLeft: theme.spacing(10),
      },
    },
    carddiv: {
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(5),
        marginLeft: theme.spacing(25),
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(5),
        marginLeft: theme.spacing(5),
      },
    },
    typograpyCardtext: {
      [theme.breakpoints.up("sm")]: {
        fontWeight: "bold",
      },
    },
    cardsize: {
      [theme.breakpoints.down('sm')]: {
        width: 150,
        height: 165,
      },
      [theme.breakpoints.up('md')]: {
        width: 230,
        height: 230,
      },
      [theme.breakpoints.up('lg')]: {
        width: 230,
        height: 230,
      },
      [theme.breakpoints.up('xl')]: {
        width: 340,
        height: 400,
      },
      "&:hover": {
        boxShadow: "rgb(226, 106, 44) 0px 20px 30px -10px"
      }
    },
    mainCardText: {
      fontFamily: Fonts.LATO,
      fontWeight: 700,
      fontSize: 22,
      [theme.breakpoints.down('sm')]: {
        fontSize: 16
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 28
      }
    },
    overallbackcolor: {
      backgroundColor: Colors.HOME_LIGHT_COLOR,
      overflow: "hidden",
      minHeight: "96.8vh",
      maxWidth: "100vw",
    },
    titlePreText: {
      marginTop: 20,
      fontFamily: Fonts.UBUNTU,
      fontWeight: "400",
      color: "#161616",
      fontSize: 35,
      [theme.breakpoints.down('sm')]: {
        fontSize: 25
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 35
      },
    },
    titleText: {
      marginTop: 20,
      fontFamily: Fonts.UBUNTU,
      fontWeight: "500",
      color: Colors.HOME_MAIN_COLOR,
      fontSize: 35,
      [theme.breakpoints.down('sm')]: {
        fontSize: 25
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 35
      },
    },
    subCard: {
      display: "flex",
      flexDirection: "column",
      width: 310,
      height: 85,
      [theme.breakpoints.down('sm')]: {
        width: 150,
        height: 88,
      },
    },
    subCardCusOr: {
      display: "flex",
      flexDirection: "column",
      width: 310,
      height: 85,
      [theme.breakpoints.down('sm')]: {
        width: 310,
        height: 60,
      }
    },
    subCardText: {
      display: "flex", flexDirection: "column", marginLeft: 5
    },
    subCardTextTitle: {
      fontFamily: Fonts.LATO,
      fontWeight: 500,
      fontSize: 20,
      marginTop: 6,
      [theme.breakpoints.down('sm')]: {
        fontSize: 17,
        color: 'grey'
      },
    },
    subCardTextContent: {
      color: Colors.HOME_MAIN_COLOR, fontFamily: Fonts.LATO, fontWeight: "bold", fontSize: 30,
      [theme.breakpoints.down('sm')]: {
        fontSize: 18
      },
    },
    container1: {
      display: "flex", flexDirection: "column", alignItems: "center"
    },
    container2: {
      display: "flex", flexWrap: "wrap", margin: "1%", justifyContent: "center"
    },
    container3: {
      backgroundColor: "#FAFAFE", display: designTeamHomeItemsBlock
    },
    container4: {
      display: designTeamHomeItemsBlock
    },
    subContainer: {
      display: "flex", justifyContent: "center"
      
      
    },
    subSubContainer: {
      display: "flex", flexWrap: "wrap", justifyContent: "center"
    },
    iconsStyle: {
      alignItems: "center", justifyContent: "center", display: "flex", marginLeft: 10, marginRight: 10
    }
  }));
  const navigate = useNavigate();
  const { state } = useLocation();
  const { userName, tohide } = state;
  const [user, setuser] = useState("");
  const colorofcard = [Colors.CUSTOMER_MAIN_COLOR, Colors.ORDER_MAIN_COLOR, Colors.REPORT_MAIN_COLOR, Colors.RATE_MAIN_COLOR]
  const [imgofcard, setimgofcard] = useState([customerDetails, orders, report, ratesUpdater]);
  const [nameofcard, setnameofcard] = useState(["Customer Details", "Order Details", "Report", "Rates Updater"]);
  const OrdersCardName = ["Today's Orders", "Today's Deliveries", "All Orders", "Confirmed Orders", "Processing Orders", "Ready Orders", "Delivered Orders", "Assigned Orders"];
  const CustomerCardName = ["All Customers", "New Customers Today"];
  const DressCardName = ["Salwar Ordered Today", "Blouse Ordered Today", "Salwar", "Blouse"];
  const [ordersCountResponseData, setOrdersCountResponseData] = useState({});
  const [designTeamHomeItemsBlock, setDesignTeamHomeItemsBlock] = useState("")

  var dtImgofcard = [orders];
  var dtNameofcard = ["Order Details"];
  var custImgofcard = [orders];
  var custNameofcard = ["Order Details"];

  const getOrdersDashboardData = () => {
    var dataToSend = { user: "admin" };
    axios
      .post(Helpers().apiURL + "/dashBoard", dataToSend)
      .then((response) => {
        setOrdersCountResponseData(response.data.message)
      })
  }

  const onHomeCardClick = (text) => {
    if (text === "Customer Details") {
      navigate('/customer-details', { state: { currentUser: user } });
    }
    else if (text === "Order Details") {
      navigate('/orderDetailPage', { state: { userName: user, prevPage:0 } });
    }
    else if (text === "Report") {
      navigate('/reports', { state: { currentUser: user } });
    }
    else if (text === "Rates Updater") {
      navigate('/ratesupdater', { state: { currentUser: user } });
    }
    else {
      alert("Under Construction");
    }
  };
  const sessionCheck = () => {
    let currentDateTime = new Date();
    let sessionDateTime = store.session("date");

    if (sessionDateTime > currentDateTime.toISOString()) {
      console.log(true)
    }
    else {
      navigate("/");
      return
    }
  }



  useEffect(() => {
    // Session Check
    sessionCheck()
    try {
      getOrdersDashboardData()
      setuser(userName);
      if (userName === "Designing Team") {
        setimgofcard(dtImgofcard);
        setnameofcard(dtNameofcard);
        setDesignTeamHomeItemsBlock("none")
      }
      else if (userName === "Shop Owner") {
        setimgofcard(imgofcard);
        setnameofcard(nameofcard);
      }
      else {
        setimgofcard(custImgofcard);
        setnameofcard(custNameofcard);
      }
    }
    catch (err) {
      navigate("/");
    }
  }, []);

  const classes = useStyles();
  const orderIcons = [
    <FontAwesomeIcon style={{ color: "#CA965C" }} className={classes.smallsize} icon={faCalendarWeek} />,
    <FontAwesomeIcon style={{ color: "#876445" }} className={classes.smallsize} icon={faCalendarWeek} />,
    <FontAwesomeIcon style={{ color: "#35858B" }} className={classes.smallsize} icon={faBoxes} />,
    <FontAwesomeIcon style={{ color: "#2940D3" }} className={classes.smallsize} icon={faCheck} />,
    <FontAwesomeIcon style={{ color: "#D83A56" }} className={classes.smallsize} icon={faSpinner} />,
    <FontAwesomeIcon style={{ color: "#B24080" }} className={classes.smallsize} icon={faShoppingBag} />,
    <FontAwesomeIcon style={{ color: "#1C7947" }} className={classes.smallsize} icon={faCheckDouble} />,
    <FontAwesomeIcon style={{ color: "#FF5959" }} className={classes.smallsize} icon={faFolder} />
  ]

  const customerIcons = [
    <FontAwesomeIcon style={{ color: "#FF7F3F" }} className={classes.smallsize} icon={faUsers} />,
    <FontAwesomeIcon style={{ color: "#EA5C2B" }} className={classes.smallsize} icon={faUser} />,
  ]

  const dressIcons = [
    <Avatar style={{ backgroundColor: Colors.SALWAR_COLOR, height: 50, width: 50 }}  >S</Avatar>,
    <Avatar style={{ backgroundColor: Colors.BLOUSE_COLOR, height: 50, width: 50 }}  >B</Avatar>,
    <Avatar style={{ backgroundColor: Colors.SALWAR_COLOR, height: 50, width: 50 }}  >S</Avatar>,
    <Avatar style={{ backgroundColor: Colors.BLOUSE_COLOR, height: 50, width: 50 }}  >B</Avatar>
  ]

  return (
    <>
      <div className={classes.overallbackcolor}>
        {/* AppBar */}
        <div>  <AppBarHead dataParent={{ userNameFrom: userName, appBtnColor: Colors.HOME_MAIN_COLOR, appBtnText: "Home" }} />  </div>
        {/* MainContent */}
        <Zoom in={true} >
          <div>
            <div className={classes.container1}>
              <Typography className={classes.titleText} >
                Welcome
              </Typography>
            </div>

            {/* Root */}
            <div className={classes.container2} >
              {/* Items */}
              {nameofcard.map((text, index) => (
                <div style={{ margin: '3%' }} >
                  <Card onClick={() => onHomeCardClick(text)} elevation={5} style={{ display: "flex", flexDirection: "column", backgroundColor: colorofcard[index], paddingTop: '20%', cursor: 'pointer' }} className={classes.cardsize}  >
                    <Card style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', padding: '10%', backgroundColor: 'white' }} square>
                      <img alt="card Name" src={imgofcard[index]}></img>
                    </Card>
                    <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', paddingTop: '10%', paddingBottom: '10%' }}>
                      <Typography className={classes.mainCardText} style={{ color: 'white' }}> {text}</Typography>
                    </div>
                  </Card>
                </div>
              ))}
            </div>
          
            <div className={classes.container3}>
           
              <div  className={classes.subContainer}>
  
             
              <Typography className={classes.titlePreText} >Our&nbsp; </Typography>
                <Typography className={classes.titleText} >
                   Orders
                </Typography>
              </div>
              <div className={classes.subSubContainer}>
                {OrdersCardName.map((text, index) => (
                  <div style={{ margin: '3%' }} >
                    <Card elevation={5} className={classes.subCard} >
                      <div style={{ display: "flex" }} >
                        <div className={classes.iconsStyle}>
                          {orderIcons[index]}
                        </div>
                        <div>
                          <div className={classes.subCardText}>
                            <Typography className={classes.subCardTextTitle}>
                              {text}
                            </Typography>
                            <Typography className={classes.subCardTextContent}>
                              {ordersCountResponseData[text]}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                ))}
              </div>
            </div>

            <div className={classes.container4}>
              <div className={classes.subContainer}>
              <Typography className={classes.titlePreText} >Our&nbsp; </Typography>

                <Typography className={classes.titleText} >
                  Customers
                </Typography>
              </div>
              <div className={classes.subSubContainer}>
                {CustomerCardName.map((text, index) => (
                  <div style={{ margin: '3%' }} >
                    <Card elevation={5} className={classes.subCardCusOr} >

                      <div style={{ display: "flex" }} >
                        <div className={classes.iconsStyle}>
                          {customerIcons[index]}
                        </div>
                        <div>
                          <div className={classes.subCardText}>
                            <Typography className={classes.subCardTextTitle}>
                              {text}
                            </Typography>
                            <Typography className={classes.subCardTextContent}>
                              <CountUp end={parseInt(ordersCountResponseData[text])} duration={2} />
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                ))}
              </div>
            </div>

            <div className={classes.container3}>
              <div className={classes.subContainer}>
              <Typography className={classes.titlePreText} >Dress&nbsp; </Typography>

                <Typography className={classes.titleText} >
                   Sales
                </Typography>
              </div>
              <div className={classes.subSubContainer}>
                {DressCardName.map((text, index) => (
                  <div style={{ margin: '3%' }} >
                    <Card elevation={5} className={classes.subCardCusOr} >
                      <div style={{ display: "flex" }} >
                        <div className={classes.iconsStyle}>
                          {dressIcons[index]}
                        </div>
                        <div>
                          <div className={classes.subCardText}>
                            <Typography className={classes.subCardTextTitle}>
                              {text}
                            </Typography>
                            <Typography className={classes.subCardTextContent}>
                              {ordersCountResponseData[text]}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                ))}
              </div>
            </div>

          </div>
        </Zoom>
      </div>
      <Footer dataBackParent={{ backColor: bgColor }} />
    </>
  );
}