import { React, useState ,useEffect} from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { TextField, makeStyles, InputAdornment, IconButton, } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import { useNavigate } from "react-router";
import PersonIcon from "@material-ui/icons/Person";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import Footer from "./Footer";
import swal from "sweetalert2";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import axios from "axios";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Helpers from './Helpers'
import store from "store2";
import ShivaneKomalaLogoBlack from './images/login/ShivaneKomalaLogoBlack.png'
import { Fonts, Colors } from "./constants";


const mainColor = Colors.HOME_MAIN_COLOR
const lightColor = "#ffe6f0"
const useStyles = makeStyles((theme) => ({
  textFieldbox: {
    width: 362, backgroundColor: "white",
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: mainColor,
    },

  },
  loginBtn: {
    backgroundColor: Colors.HOME_MAIN_COLOR, minHeight: 50, minWidth: 362, color: "white", fontWeight: "bold", marginTop: 30, fontFamily: Fonts.UBUNTU,
    "&:hover": {
      backgroundColor: Colors.HOME_MAIN_COLOR,
    },
    "&:disabled": {
      backgroundColor: Colors.HOME_MAIN_COLOR,color:"white"
    }
  },

  forgotPwd: {
    textAlign: 'end',
    color: 'black',
    fontSize: 14,
    fontFamily: Fonts.LATO,
    marginTop: 30,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  mainCard: {
    width: 500, height: 500, display: "flex", flexDirection: "column", backgroundColor: "white", alignItems: "center", border: "1px solid", borderColor: mainColor,
    [theme.breakpoints.down('sm')]: {
      width: 380,
      border: "none",
      boxShadow: "none",
      paddingTop: 0,
      backgroundColor: "#ffe6f0",
    },
    [theme.breakpoints.up('sm')]: {
      width: 500,
    },
    [theme.breakpoints.up('md')]: {
      width: 500,
    },
    [theme.breakpoints.up('lg')]: {
      width: 500,
    },
    [theme.breakpoints.up('xl')]: {
      width: 500,
    }
  },
  root: {
    backgroundColor: "#ffe6f0", display: "flex", flexDirection: "column", alignItems: "center", height: "96.8vh",
    [theme.breakpoints.up('sm')]: {
      justifyContent: "center"
    },
  },
  mainLogo: {
    width: "40%", height: "30%",
    [theme.breakpoints.down('sm')]: {
      width: "60%",
      height: "40%",
    },

  }
}));

export default function LoginPage() {
  const navigate = useNavigate();
  const classes = useStyles();

  let date = new Date();
  
  let addminutes = new Date(date.getTime() + (60 * 400000))

  const [buttonVisblity, setButtonVisblity] = useState(false);
  const [loginBtnLoading, setLoginBtnLoading] = useState(false);
  const [loginBtnText, setLoginBtnText] = useState("Login");
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [loader, setLoader] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [alert1, setAlert1] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");


  const handleClose = (event, reason) => {
    if (reason === "clickway") {
      return;
    }
    setAlert1(false);
  };

  const handleUserNameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleUserPasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onLoginClick();
    }
  };

  const apiChecker = () => {
    // setLoader(true)
    axios.get(Helpers().apiURL + "/apiCheck").then((result) => {
      var res = result.data;
      // setLoader(false)
      let loginData = { user: "admin", username: username, password: password }
      axios.post(Helpers().apiURL + "/login", loginData).then((res) => {
        var resdata = res.data
        let users = resdata.message
        if (users === "Shop Owner") {
          store.session("date", addminutes);
          navigate('/homepage', { state: { userName: users, tohide: '' } });
        }
        else if (users === "Designing Team") {
          store.session("date", addminutes);
          navigate('/homepage', { state: { userName: users, tohide: 'none' } });
        }
        else {
          setButtonVisblity(false)
          setLoginBtnLoading(false)
          setLoginBtnText("Login")
          setAlert1(true);
          setAlertMessage("Please enter valid username and password!");
        }
      })
    })
      .catch((error) => {
        setButtonVisblity(false)
        setLoginBtnLoading(false)
        setLoginBtnText("Login")
        // setLoader(false)
        showAlert()
      });
  };

  const showAlert = () => {
    swal.fire({ title: `Server Down`, text: "", icon: "warning", confirmButtonColor: "#3085d6", cancelButtonColor: "#d33", })
      .then((willWarn) => {
        if (willWarn.isConfirmed) {
          // ok
        }
      });
  };
  const onLoginClick = async (e) => {
    setButtonVisblity(true)
    setLoginBtnLoading(true)
    setLoginBtnText("Logging in...")
    if (username === "" || password === "") {
      setButtonVisblity(false)
      setLoginBtnLoading(false)
      setLoginBtnText("Login")
      setAlert1(true);
      setAlertMessage("Please enter username and password!");
      return;
    }
    apiChecker();
  };


  useEffect(() => {
    store.clearAll()
  }, []);
  return (
    <div>
      <div className={classes.root} >

        <Card elevation={5} className={classes.mainCard}>

          <img
            alt="side logo"
            src={ShivaneKomalaLogoBlack}
            className={classes.mainLogo}
          />
          <Card style={{ marginTop: 30, }}>

            <TextField
              className={classes.textFieldbox}
              type="text"
              onChange={handleUserNameChange}
              placeholder="Enter Username"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", backgroundColor: mainColor, height: 56, width: 50, marginRight: 5, marginLeft: -13 }} >
                    <InputAdornment position="start">
                      <PersonIcon style={{ marginLeft: 10, color: "white" }} />
                    </InputAdornment>
                  </div>
                ),
              }}
            />

          </Card>
          <Card style={{ marginTop: 30, }}>
            <TextField
              className={classes.textFieldbox}
              type={showPassword ? "text" : "password"}
              onChange={handleUserPasswordChange}
              onKeyDown={handleKeyPress}
              placeholder="Enter Password"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", backgroundColor: mainColor, height: 56, width: 50, marginRight: 5, marginLeft: -13 }}>
                    <InputAdornment position="start">
                      <VpnKeyIcon style={{ marginLeft: 10, color: "white" }} />
                    </InputAdornment>
                  </div>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} >
                      {showPassword ? (<VisibilityOff />) : (<Visibility />)}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Card>
          <Button className={classes.loginBtn} loading={loginBtnLoading} loadingPosition="end" variant="contained" disabled={buttonVisblity} onClick={onLoginClick} > {loginBtnText} </Button>
          <Typography className={classes.forgotPwd} onClick={() => { navigate('/resetPage'); }}>Forgot Password ?</Typography>
        </Card>

        <div>
          <Snackbar open={alert1} autoHideDuration={2000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              {alertMessage}
            </Alert>
          </Snackbar>
        </div>
        <Backdrop className={classes.backdrop} open={loader} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Footer dataBackParent={{ backColor: lightColor }} />    </div>
  );
}
