import Webcam from "react-webcam";
import { Avatar, Tooltip, Button, IconButton, InputLabel, Box, styled, Checkbox, TextField, Card, Typography, Select, MenuItem, FormControl, Dialog, DialogTitle, InputAdornment, Menu } from "@material-ui/core";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, React, useMemo, useCallback, memo, useContext } from "react";
import axios from "axios";
import Helpers from "./Helpers";
import useState from "react-usestateref";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CachedIcon from '@mui/icons-material/Cached';
import { Colors, Fonts } from "./constants";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PrintIcon from '@mui/icons-material/Print';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { faCalendarWeek, faBoxes, faCheck, faSpinner, faShoppingBag, faCheckDouble, faFolder, faUsers, faUser, } from '@fortawesome/free-solid-svg-icons'

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

// skirt Color Codes
const skirtColorCode = Colors.SKIRT_COLOR;
const skirtLightColorCode = Colors.SKIRT_LIGHT_COLOR;

function SkirtDressComponent(props) {
  const mode = props.mode
  const skirtDataRef = props.skirtDataRef
  const [skirtDataObj, setSkirtDataObj, skirtDataObjRef] = useState(props.skirtDataObj)
  const [tabValue, setTabValue] = useState("1");
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const useStyles = useMemo(() => {
    return makeStyles((theme) => ({
      topBarText: {
        fontSize: 14, fontFamily: Fonts.LATO, height: 35,
        [theme.breakpoints.up("lg")]: {
          fontSize: 15,
        },
      },

      selectText: {
        "&:after": {
          borderBottomColor: "darkred",
        },
        fontSize: 14, fontFamily: Fonts.LATO, fontWeight: 500,
        [theme.breakpoints.up("lg")]: {
          fontSize: 15,
        },
      },
      dressBlockTitleText: {
        fontSize: 14, fontFamily: Fonts.UBUNTU, fontWeight: "BOLD", color: "white",
        [theme.breakpoints.up("lg")]: {
          fontSize: 16,
        },
      },
      dressBlockCloseBtn: {
        backgroundColor: "#FF4848", color: "white", fontSize: 14, fontWeight: "bold", display: mode === "view" ? "none" : "block", padding: 0,
        "&:hover": {
          backgroundColor: "#FF4848",
        },
      },
      dressBlockIDText: {
        fontWeight: "bold", height: 0, color: "white", fontFamily: Fonts.LATO,
      },
      dressBlockSubHeadText: {
        color: "white", marginLeft: "10px", marginTop: 5, textAlign: "start", fontWeight: "bold", flex: 1, fontFamily: Fonts.LATO, fontSize: 14
      },
      adornment: {
        backgroundColor: skirtColorCode,
        width: "550px",
        paddingTop: "8%",
        paddingBottom: "8%",
        paddingLeft: "10px",
        borderTopLeftRadius: theme.shape.borderRadius + "px",
        borderBottomLeftRadius: theme.shape.borderRadius + "px",
      },

      textFieldCD: {
        fontWeight: "bold", fontSize: 14, fontFamily: Fonts.LATO,
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: skirtColorCode,
        },
      },
      textField: {
        "& .MuiFormLabel-root.Mui-focused": {
          color: skirtColorCode
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: skirtColorCode,
        },
        "& .MuiOutlinedInput-root": {
          paddingLeft: 0,
        },
        '& .MuiOutlinedInput-input': {
          '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
          }

        }
      },


      imagePreview: {
        width: "20%",
        height: "20%",
        "&:hover": {
          transform: "scale(2.0)",
          transition: "all 0.8s ease",
        },
      },
      button: {
        margin: theme.spacing(1),
      },
      sbCardheight: {
        [theme.breakpoints.down("md")]: {
          height: "100%",
          background: "#EEEEEE",
          marginBottom: "60px",
        },
        [theme.breakpoints.up("lg")]: {
          background: "#EEEEEE",
          marginBottom: "45px",
        },
      },


      skirtMeasurementTextField: {
        [`& fieldset`]: {
          borderRadius: 0,
        },

        // focused color for input with variant='outlined'
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: skirtColorCode
          }
        }

      },

      skirtMeasurementHeadText: {
        width: 145, backgroundColor: skirtColorCode, color: "white", alignItems: "center", display: "flex", paddingLeft: "3%", borderRadius: 0, fontSize: 14, fontFamily: Fonts.LATO,
        [theme.breakpoints.up("xl")]: {
          fontSize: 16,
        },
      },
      personNameContainer: {
        display: "flex", justifyContent: "right", flex: 1, flexWrap: "wrap"
      },
    }))
  }, []);

  const bigSkirtMeasurements = useMemo(() => { return ["Arm Length", "Arm Circum", "Ankle", "Skirt Length"] }, []);
  // skirt Reqs
  const skirtTextFields = useMemo(() => { return ["Shoulder Size", "Shoulder Width", "Breast Circum", "Breast Size", "Hip", "Waist", "Neck F", "Neck B", "Full Length", "Side Slit", "Arm Hole", "Arm Length", "Arm Circum", "Ankle", "Skirt Length"]; }, []);
  const skirtTuckStyle = useMemo(() => { return ["Tuck Point", "Tuck Side"]; }, []);
  const skirtCheckBoxFields = useMemo(() => { return [ "Rope", "Zip", "With Elastic"]; }, []);
  const skirtDesigningStyle = useMemo(() => { return ["Dart", "Neck Type", "Piping", "Lining"]; }, []);

  const skirtPatternStyle = useMemo(() => { return ["Neck Pattern ID", "Sleeve Pattern ID"]; }, []);
  const skirtLiningDropDownData = useMemo(() => { return ["With Lining", "Without Lining"]; }, []);
  const skirtPipingDropDownData = useMemo(() => { return ["None", "Piping-Only Neck", "Piping-Neck Sleeve", "Side Slit", "Piping Sleeves Only", "Double Piping Neck Only", "Double Piping Sleeves Only", "Double Piping Neck and Sleeves Only", "Triple Piping Sleeves Only", "Triple Piping Neck Only", "Triple Piping Neck and Sleeves Only"]; }, []);
  const skirtDartDropDownData = useMemo(() => { return ["None", "Only Front", "Only Back", "Both"]; }, []);

  const skirtNeckTypeDropDownData = useMemo(() => { return ["None", "Lotus", "Square", "V Neck", "Round", "Close Neck", "Boat Neck", "Collar Neck","U Neck","High Neck"]; }, []);
  const skirtMeasurementList = useMemo(() => { return ["Shoulder Size", "Shoulder Width", "Breast Circum", "Breast Size", "Hip", "Waist", "Arm Hole", "Arm Length", "Arm Circum", "Neck F", "Neck B", "Full Length", "Ankle", "Skirt Length", "Side Slit", "Tuck Point", "Tuck Side"]; }, []);
  const skirtObjectKeysCheckList = useMemo(() => { return ['skirtOrderId', 'infoCompletionStatus', 'Amount', 'skirtMeasurementCheck', 'itemDeliverStatus', 'Shoulder Size', 'Shoulder Width', 'Breast Circum', 'Breast Size', 'Hip', 'Waist', 'Neck F', 'Neck B', 'Full Length', 'Side Slit', 'Arm Hole', 'Arm Length', 'Arm Circum', 'Ankle', 'Skirt Length', 'personName', 'Tuck Point', 'CostEstimateFinal', 'Tuck Side', 'Dart', 'Neck Type', 'Piping', 'pipingNeckCheck', 'pipingNeckSleeveCheck', 'pipingSideSlitCheck', 'Lining']; }, []);
  const skirtWorker = [{name:"Worker Name",dbvalue:"workerName"},{name:"Working Hrs",dbvalue:"workerHrs"},{name:"Aari Worker Name",dbvalue:"aariWorkerName"}, {name:"Aari Working Hrs",dbvalue:"aariWorkerHrs"}]


  const pantStyleDropDownData = useMemo(() => { return ["None", "Normal", "S.Patiyala", "Parallel with Rope", "Parallel with Elastic", "Parallel with Palazzo", "Pencil", "Ankle", "Gathering Pant", "Parallel Elastic|Belt"]; }, []);
  const [costEstimateFinal, setCostEstimateFinal, costEstimateFinalRef] = useState("");
  const [open, setopen] = useState(false);
  const [imageIndex, setImageIndex] = useState();

  const [skirtCaptureDialog, setskirtCaptureDialog] = useState(false);
  const [skirtDressImageDialogOpener, setskirtDressImageDialogOpener] = useState(false);
  const [facingMode, setFacingMode] = useState("user");
  const [cameraLoading, setCameraLoading] = useState(true);

  const defaultImage = "https://image.freepik.com/free-vector/cardboard-box-opened-isolated-cartoon-style_1308-49807.jpg"

  const [skirtStichDressImageDialogOpener, setskirtStichDressImageDialogOpener] = useState(false);

  const [skirtpatternview, setskirtpatternview] = useState("")
  const [skirtpatterviewname, setskirtpatterviewname] = useState("")
  const Input = styled("input")({ display: "none", });

  const textBoxDisabler = mode === "view" ? true : false

  // Props
  const skirtDataindex = props.skirtmainIndex
  const costs = props.costs
  const skirtData = props.skirtData
  const setSkirtData = props.setSkirtData
  const skirtCounter = props.skirtCounter
  const setSkirtCounter = props.setSkirtCounter
  const skirtPrevRegNames = props.skirtPrevRegNames
  const designTeamContentHider = props.designTeamContentHider
  const skirtPricesToShow = props.skirtPricesToShow
  console.log(skirtPricesToShow)
  const mobNo = props.mobNo
  const sweetAlertShow = props.sweetAlertShow


  const setSkirtDataObjState = (dataField, value) => {
    if (dataField === "designSelection") {
      setSkirtDataObj((prevState) => {
        let newItem = { ...prevState, Amount: prevState.Amount + value }
        // sync with [parent array]
        props.onSkirtDataChange(skirtDataindex, newItem);
        return newItem;
      });
    }
    else {
      setSkirtDataObj((prevState) => {
        let newItem = { ...prevState, ...value }
        // sync with [arent array]
        props.onSkirtDataChange(skirtDataindex, newItem);

        return newItem;
      });
    }


  }

  // const handleTabChange = (event, newValue) => {
  //   console.log(newValue);
  //   setValue(newValue);
  // };

  // On Close skirt Card
  const onSkirtBtnClickClose = OrderId => {
    console.log(skirtData)
    setSkirtCounter(skirtCounter - 1);
    setSkirtData(skirtData.filter(item => item.skirtOrderId !== OrderId));
    
  };

  const onMeasurementValueSet = async (e, text, orderID, dataHeadName, skirtDataindex) => {
    const startTime = new Date().getTime();
    if (dataHeadName === "measurements") {
      if (text !== "Remarks" && /[a-z]/i.test(e.target.value)) {
        return
      }
      setSkirtDataObj((prevState) => {
        let newItem = prevState.skirtMeasurementCheck ? { ...prevState, [text]: e.target.value } : { ...prevState, [text]: e.target.value, Amount: prevState.Amount, skirtMeasurementCheck: true, };

        // sync with [parent array]
        props.onSkirtDataChange(skirtDataindex, newItem);

        return newItem;
      });

    }
    if (dataHeadName === "deliverSelection") {
      setSkirtDataObj((prevState) => {
        let newItem = { ...prevState, itemDeliverStatus: e.target.textContent }
        // sync with [parent array]
        props.onSkirtDataChange(skirtDataindex, newItem);

        return newItem;
      });
    }
    if (dataHeadName === "personNameSelected") {
      // New Code
      if (e.target.value === "None") {
        removeData("skirt", orderID, skirtMeasurementList);
        setSkirtDataObj((prevState) => {
          let newItem = {
            ...prevState, [text]: e.target.value,
            personNameDisabler: false,
            personName: "",
            personNameVisibler: "",
          }
          // sync with [parent array]
          props.onSkirtDataChange(skirtDataindex, newItem);

          return newItem;
        });
      }
      else if(e.target.value=== "sameAsAbove"){
        console.log("SameAsAbove")

      }
      else {
        getskirtMeasurementDataForPerson(orderID, e.target.value, "skirt", text);
      }
    }
    if (dataHeadName === "personName") {
      if (e.target.value === "") {
        setSkirtDataObj((prevState) => {
          let newItem = {
            ...prevState, [text]: e.target.value,
            personNameSelectorDisabler: false,
          }
          // sync with [arent array]
          props.onSkirtDataChange(skirtDataindex, newItem);
          return newItem;
        });
      }
      else {
        setSkirtDataObj((prevState) => {
          let newItem = {
            ...prevState, [text]: e.target.value,
            personNameSelectorDisabler: true,
          }
          // sync with [arent array]
          props.onSkirtDataChange(skirtDataindex, newItem);
          return newItem;
        });
      }
    }
    if (dataHeadName === "designSelection") {

      setSkirtDataObj((prevState) => {
        let newItem = { ...prevState, [text]: e.target.checked, }
        props.onSkirtDataChange(skirtDataindex, newItem);
        return newItem;
      });

      if (text === "Pocket" && e.target.checked === true) {
        setSkirtDataObj((prevState) => {
          let newItem = { ...prevState, Amount: prevState.Amount + costs["skirt"]["skirtItemsUtilitiesList"] }
          props.onSkirtDataChange(skirtDataindex, newItem);
          return newItem;
        });

        // setskirtData((prev) => prev.map((el) => el.skirtOrderId === orderID ? { ...el, Amount: el.Amount + costs["skirt"]["skirtItemsUtilitiesList"]["Pocket"], } : el));
      } else if (text === "Pocket" && e.target.checked === false) {
        setSkirtDataObj((prevState) => {
          let newItem = { ...prevState, Amount: prevState.Amount - costs["skirt"]["skirtItemsUtilitiesList"] }
          props.onSkirtDataChange(skirtDataindex, newItem);
          return newItem;
        });


      }

      if (text === "Rope" && e.target.checked === true) {
        console.log("Rope ", {skirtDataindex} )
        setSkirtDataObj((prevState) => {
          let newItem = { ...prevState, Amount: prevState.Amount + costs["skirt"]["skirtItemsUtilitiesList"]["Rope"] }
          props.onSkirtDataChange(skirtDataindex, newItem);
          return newItem;
        });

      } else if (text === "Rope" && e.target.checked === false) {
        setSkirtDataObj((prevState) => {
          let newItem = { ...prevState, Amount: prevState.Amount - costs["skirt"]["skirtItemsUtilitiesList"]["Rope"] }
          props.onSkirtDataChange(skirtDataindex, newItem);
          return newItem;
        });
      }

      if (text === "Zip" && e.target.checked === true) {
        setSkirtDataObj((prevState) => {
          let newItem = { ...prevState, Amount: prevState.Amount + costs["skirt"]["skirtItemsUtilitiesList"]["Zip"] }
          props.onSkirtDataChange(skirtDataindex, newItem);
          return newItem;
        });
      } else if (text === "Zip" && e.target.checked === false) {
        setSkirtDataObj((prevState) => {
          let newItem = { ...prevState, Amount: prevState.Amount - costs["skirt"]["skirtItemsUtilitiesList"]["Zip"] }
          props.onSkirtDataChange(skirtDataindex, newItem);
          return newItem;
        });
      }

      if (text === "With Elastic" && e.target.checked === true) {
        setSkirtDataObj((prevState) => {
          let newItem = { ...prevState, Amount: prevState.Amount + costs["skirt"]["skirtItemsUtilitiesList"]["With Elastic"] }
          props.onSkirtDataChange(skirtDataindex, newItem);
          return newItem;
        });
      }
      else if (text === "With Elastic" && e.target.checked === false) {
        setSkirtDataObj((prevState) => {
          let newItem = { ...prevState, Amount: prevState.Amount - costs["skirt"]["skirtItemsUtilitiesList"]["With Elastic"] }
          props.onSkirtDataChange(skirtDataindex, newItem);
          return newItem;
        });

      }
    }
    if (dataHeadName === "designStyle") {
      if (text === "Dart") {
        setSkirtDataObj((prevState) => {
          let newItem = { ...prevState, [text]: e.target.value }
          props.onSkirtDataChange(skirtDataindex, newItem);
          return newItem;
        });
        return
      }


      let tempData = skirtDataObj
      // Pant Start
      // if (text === "Pant Style") {
      //   if (skirtDataObj.parallelElasticBeltCheck) {
      //     setSkirtDataObj((prevState) => {
      //       let newItem = {
      //         ...prevState, [text]: e.target.value,
      //         Amount: prevState.Amount - costs["skirt"]["skirtItemsPantList"]["Parallel Elastic|Belt"],
      //         parallelElasticBeltCheck: false
      //       }
      //       props.onSkirtDataChange(skirtDataindex, newItem);
      //       return newItem;
      //     });
      //   }
      //   else {
      //     setSkirtDataObj((prevState) => {
      //       let newItem = {
      //         ...prevState, [text]: e.target.value,
      //         parallelElasticBeltCheck: false
      //       }
      //       props.onSkirtDataChange(skirtDataindex, newItem);
      //       return newItem;
      //     });
      //   }

      //   if (text === "Pant Style" && e.target.value === "Parallel Elastic|Belt") {
      //     setSkirtDataObj((prevState) => {
      //       let newItem = {
      //         ...prevState, [text]: e.target.value,
      //         Amount: prevState.Amount + costs["skirt"]["skirtItemsPantList"]["Parallel Elastic|Belt"],
      //         parallelElasticBeltCheck: true
      //       }
      //       props.onSkirtDataChange(skirtDataindex, newItem);
      //       return newItem;
      //     });
      //   }
      // }

      // Pant Start

      // Neck Start

      if (text === "Neck Type") {
        if (skirtDataObj.boatNeckCheck) {
          setSkirtDataObj((prevState) => {
            let newItem = {
              ...prevState, [text]: e.target.value,
              Amount: prevState.Amount - costs["skirt"]["skirtItemsNeckList"]["Boat - Neck"],
              boatNeckCheck: false, collarNeckCheck: false
            }
            props.onSkirtDataChange(skirtDataindex, newItem);
            return newItem;
          });
        }
        else if (skirtDataObj.collarNeckCheck) {
          setSkirtDataObj((prevState) => {
            let newItem = {
              ...prevState, [text]: e.target.value,
              Amount: prevState.Amount - costs["skirt"]["skirtItemsNeckList"]["Collar - Neck"],
              boatNeckCheck: false, collarNeckCheck: false
            }
            props.onSkirtDataChange(skirtDataindex, newItem);
            return newItem;
          });
        }
        else {
          setSkirtDataObj((prevState) => {
            let newItem = {
              ...prevState, [text]: e.target.value,
              boatNeckCheck: false, collarNeckCheck: false
            }
            props.onSkirtDataChange(skirtDataindex, newItem);
            return newItem;
          });
        }
      }

      if (text === "Neck Type" && e.target.value === "Boat Neck") {
        setSkirtDataObj((prevState) => {
          let newItem = {
            ...prevState, [text]: e.target.value,
            Amount: prevState.Amount + costs["skirt"]["skirtItemsNeckList"]["Boat - Neck"],
            boatNeckCheck: true, collarNeckCheck: false
          }
          props.onSkirtDataChange(skirtDataindex, newItem);
          return newItem;
        });
      }
      else if (text === "Neck Type" && e.target.value === "Collar Neck") {
        setSkirtDataObj((prevState) => {
          let newItem = {
            ...prevState, [text]: e.target.value,
            Amount: prevState.Amount + costs["skirt"]["skirtItemsNeckList"]["Collar - Neck"],
            boatNeckCheck: false, collarNeckCheck: true
          }
          props.onSkirtDataChange(skirtDataindex, newItem);
          return newItem;
        });

      }


      // Neck End

      // Lining start
      if (text === "Lining") {

        if (skirtDataObj.liningCheck) {
          setSkirtDataObj((prevState) => {
            let newItem = {
              ...prevState, [text]: e.target.value,
              Amount: prevState.Amount - costs["skirt"]["skirtItemsLiningList"]["With Lining"], liningCheck: false
            }
            props.onSkirtDataChange(skirtDataindex, newItem);
            return newItem;
          });
        }
        else {
          setSkirtDataObj((prevState) => {
            let newItem = {
              ...prevState, [text]: e.target.value,
              liningCheck: false
            }
            props.onSkirtDataChange(skirtDataindex, newItem);
            return newItem;
          });
        }

      }

      if (text === "Lining" && e.target.value === "With Lining") {
        setSkirtDataObj((prevState) => {
          let newItem = {
            ...prevState, [text]: e.target.value,
            Amount: prevState.Amount + costs["skirt"]["skirtItemsLiningList"]["With Lining"],
            liningCheck: true,
          }
          props.onSkirtDataChange(skirtDataindex, newItem);
          return newItem;
        });
      }
      // Lining End

      // Piping Start

      if (text === "Piping") {

        if (skirtDataObj.pipingNeckCheck) {
          setSkirtDataObj((prevState) => {
            let newItem = {
              ...prevState, [text]: e.target.value,
              Amount: prevState.Amount - costs["skirt"]["skirtItemsPipingList"]["Piping - Neck"],
              pipingNeckCheck: false, pipingNeckSleeveCheck: false, pipingSideSlitCheck: false, pipingSleeveOnlyCheck:false, doublePipingNeckOnlyCheck:false,doublePipingSleevesOnlyCheck:false,doublePipingNeckandSleevesOnlyCheck:false,triplePipingSleevesOnlyCheck:false,triplePipingNeckOnlyCheck:false,triplePipingNeckandSleevesOnlyCheck:false
            }
            props.onSkirtDataChange(skirtDataindex, newItem);
            return newItem;
          });
        }
        else if (skirtDataObj.pipingNeckSleeveCheck) {
          setSkirtDataObj((prevState) => {
            let newItem = {
              ...prevState, [text]: e.target.value,
              Amount: prevState.Amount - costs["skirt"]["skirtItemsPipingList"]["Piping - Neck Sleeve"],
              pipingNeckCheck: false, pipingNeckSleeveCheck: false, pipingSideSlitCheck: false, pipingSleeveOnlyCheck:false, doublePipingNeckOnlyCheck:false,doublePipingSleevesOnlyCheck:false,doublePipingNeckandSleevesOnlyCheck:false,triplePipingSleevesOnlyCheck:false,triplePipingNeckOnlyCheck:false,triplePipingNeckandSleevesOnlyCheck:false
            }
            props.onSkirtDataChange(skirtDataindex, newItem);
            return newItem;
          });
        }
        else if (skirtDataObj.pipingSideSlitCheck) {
          setSkirtDataObj((prevState) => {
            let newItem = {
              ...prevState, [text]: e.target.value,
              Amount: prevState.Amount - costs["skirt"]["skirtItemsPipingList"]["Side Slit"],
              pipingNeckCheck: false, pipingNeckSleeveCheck: false, pipingSideSlitCheck: false, pipingSleeveOnlyCheck:false, doublePipingNeckOnlyCheck:false,doublePipingSleevesOnlyCheck:false,doublePipingNeckandSleevesOnlyCheck:false,triplePipingSleevesOnlyCheck:false,triplePipingNeckOnlyCheck:false,triplePipingNeckandSleevesOnlyCheck:false
            }
            props.onSkirtDataChange(skirtDataindex, newItem);
            return newItem;
          });
        }

 //Shivane New Requirement Sep2022  

        else if (skirtDataObj.pipingSleeveOnlyCheck) {
          setSkirtDataObj((prevState) => {
            let newItem = { 
              ...prevState, [text]: e.target.value,
              Amount: prevState.Amount - costs["skirt"]["skirtItemsPipingList"]["Piping-Sleeve"],
              pipingNeckCheck: false, pipingNeckSleeveCheck: false, pipingSideSlitCheck: false, pipingSleeveOnlyCheck:false, doublePipingNeckOnlyCheck:false,doublePipingSleevesOnlyCheck:false,doublePipingNeckandSleevesOnlyCheck:false,triplePipingSleevesOnlyCheck:false,triplePipingNeckOnlyCheck:false,triplePipingNeckandSleevesOnlyCheck:false
            }
            props.onSkirtDataChange(skirtDataindex, newItem);
            return newItem;
          });
        }
        else if (skirtDataObj.doublePipingNeckOnlyCheck) {
          setSkirtDataObj((prevState) => {
            let newItem = { 
              ...prevState, [text]: e.target.value,
              Amount: prevState.Amount - costs["skirt"]["skirtItemsPipingList"]["Double-Piping-Neck"],
              pipingNeckCheck: false, pipingNeckSleeveCheck: false, pipingSideSlitCheck: false, pipingSleeveOnlyCheck:false, doublePipingNeckOnlyCheck:false,doublePipingSleevesOnlyCheck:false,doublePipingNeckandSleevesOnlyCheck:false,triplePipingSleevesOnlyCheck:false,triplePipingNeckOnlyCheck:false,triplePipingNeckandSleevesOnlyCheck:false
            }
            props.onSkirtDataChange(skirtDataindex, newItem);
            return newItem;
          });
        }
        else if (skirtDataObj.doublePipingSleevesOnlyCheck) {
          setSkirtDataObj((prevState) => {
            let newItem = { 
              ...prevState, [text]: e.target.value,
              Amount: prevState.Amount - costs["skirt"]["skirtItemsPipingList"]["Double-Piping-Sleeve"],
              pipingNeckCheck: false, pipingNeckSleeveCheck: false, pipingSideSlitCheck: false, pipingSleeveOnlyCheck:false, doublePipingNeckOnlyCheck:false,doublePipingSleevesOnlyCheck:false,doublePipingNeckandSleevesOnlyCheck:false,triplePipingSleevesOnlyCheck:false,triplePipingNeckOnlyCheck:false,triplePipingNeckandSleevesOnlyCheck:false
            }
            props.onSkirtDataChange(skirtDataindex, newItem);
            return newItem;
          });
        }
        else if (skirtDataObj.doublePipingNeckandSleevesOnlyCheck) {
          setSkirtDataObj((prevState) => {
            let newItem = { 
              ...prevState, [text]: e.target.value,
              Amount: prevState.Amount - costs["skirt"]["skirtItemsPipingList"]["Double-Piping-Neck-&-Sleeve"],
              pipingNeckCheck: false, pipingNeckSleeveCheck: false, pipingSideSlitCheck: false, pipingSleeveOnlyCheck:false, doublePipingNeckOnlyCheck:false,doublePipingSleevesOnlyCheck:false,doublePipingNeckandSleevesOnlyCheck:false,triplePipingSleevesOnlyCheck:false,triplePipingNeckOnlyCheck:false,triplePipingNeckandSleevesOnlyCheck:false
            }
            props.onSkirtDataChange(skirtDataindex, newItem);
            return newItem;
          });
        }
        else if (skirtDataObj.triplePipingSleevesOnlyCheck) {
          setSkirtDataObj((prevState) => {
            let newItem = { 
              ...prevState, [text]: e.target.value,
              Amount: prevState.Amount - costs["skirt"]["skirtItemsPipingList"]["Triple-Piping-Sleeve"],
              pipingNeckCheck: false, pipingNeckSleeveCheck: false, pipingSideSlitCheck: false, pipingSleeveOnlyCheck:false, doublePipingNeckOnlyCheck:false,doublePipingSleevesOnlyCheck:false,doublePipingNeckandSleevesOnlyCheck:false,triplePipingSleevesOnlyCheck:false,triplePipingNeckOnlyCheck:false,triplePipingNeckandSleevesOnlyCheck:false
            }
            props.onSkirtDataChange(skirtDataindex, newItem);
            return newItem;
          });
        }
        else if (skirtDataObj.triplePipingNeckOnlyCheck) {
          setSkirtDataObj((prevState) => {
            let newItem = { 
              ...prevState, [text]: e.target.value,
              Amount: prevState.Amount - costs["skirt"]["skirtItemsPipingList"]["Triple-Piping-Neck"],
              pipingNeckCheck: false, pipingNeckSleeveCheck: false, pipingSideSlitCheck: false, pipingSleeveOnlyCheck:false, doublePipingNeckOnlyCheck:false,doublePipingSleevesOnlyCheck:false,doublePipingNeckandSleevesOnlyCheck:false,triplePipingSleevesOnlyCheck:false,triplePipingNeckOnlyCheck:false,triplePipingNeckandSleevesOnlyCheck:false
            }
            props.onSkirtDataChange(skirtDataindex, newItem);
            return newItem;
          });
        }
        else if (skirtDataObj.triplePipingNeckandSleevesOnlyCheck) {
          setSkirtDataObj((prevState) => {
            let newItem = { 
              ...prevState, [text]: e.target.value,
              Amount: prevState.Amount - costs["skirt"]["skirtItemsPipingList"]["Triple-Piping-Neck-&-Sleeve"],
              pipingNeckCheck: false, pipingNeckSleeveCheck: false, pipingSideSlitCheck: false, pipingSleeveOnlyCheck:false, doublePipingNeckOnlyCheck:false,doublePipingSleevesOnlyCheck:false,doublePipingNeckandSleevesOnlyCheck:false,triplePipingSleevesOnlyCheck:false,triplePipingNeckOnlyCheck:false,triplePipingNeckandSleevesOnlyCheck:false
            }
            props.onSkirtDataChange(skirtDataindex, newItem);
            return newItem;
          });
        }
        else {
          setSkirtDataObj((prevState) => {
            let newItem = {
              ...prevState, [text]: e.target.value,
              pipingNeckCheck: false, pipingNeckSleeveCheck: false, pipingSideSlitCheck: false, pipingSleeveOnlyCheck:false, doublePipingNeckOnlyCheck:false,doublePipingSleevesOnlyCheck:false,doublePipingNeckandSleevesOnlyCheck:false,triplePipingSleevesOnlyCheck:false,triplePipingNeckOnlyCheck:false,triplePipingNeckandSleevesOnlyCheck:false
            }
            props.onSkirtDataChange(skirtDataindex, newItem);
            return newItem;
          });
        }
 //Shivane New Requirement Sep2022       
      }


      if (text === "Piping" && e.target.value === "Piping-Only Neck") {
        setSkirtDataObj((prevState) => {
          let newItem = {
            ...prevState, [text]: e.target.value,
            Amount:
              prevState.Amount +
              costs["skirt"]["skirtItemsPipingList"]["Piping - Neck"],
            pipingNeckCheck: true, pipingNeckSleeveCheck: false, pipingSideSlitCheck: false, pipingSleeveOnlyCheck:false, doublePipingNeckOnlyCheck:false
          }
          props.onSkirtDataChange(skirtDataindex, newItem);
          return newItem;
        });
      }
      else if (text === "Piping" && e.target.value === "Piping-Neck Sleeve") {
        setSkirtDataObj((prevState) => {
          let newItem = {
            ...prevState, Amount: prevState.Amount + costs["skirt"]["skirtItemsPipingList"]["Piping - Neck Sleeve"],
            pipingNeckCheck: false, pipingNeckSleeveCheck: true, pipingSideSlitCheck: false, pipingSleeveOnlyCheck:false, doublePipingNeckOnlyCheck:false
          }
          props.onSkirtDataChange(skirtDataindex, newItem);
          return newItem;
        });
      }
      else if (text === "Piping" && e.target.value === "Side Slit") {
        setSkirtDataObj((prevState) => {
          let newItem = {
            ...prevState, Amount: prevState.Amount + costs["skirt"]["skirtItemsPipingList"]["Side Slit"],
            pipingNeckCheck: false, pipingNeckSleeveCheck: false, pipingSideSlitCheck: true, pipingSleeveOnlyCheck:false, doublePipingNeckOnlyCheck:false
          }
          props.onSkirtDataChange(skirtDataindex, newItem);
          return newItem;
        });
      }
      else if (text === "Piping" && e.target.value === "Piping Sleeves Only") {
        setSkirtDataObj((prevState) => {
          let newItem = {
            ...prevState, Amount: prevState.Amount + costs["skirt"]["skirtItemsPipingList"]["Piping-Sleeve"],
            pipingNeckCheck: false, pipingNeckSleeveCheck: false, pipingSideSlitCheck: false, pipingSleeveOnlyCheck:true, doublePipingNeckOnlyCheck:false
          }
          props.onSkirtDataChange(skirtDataindex, newItem);
          return newItem;
        });
      }
      else if (text === "Piping" && e.target.value === "Double Piping Neck Only") {
        setSkirtDataObj((prevState) => {
          let newItem = {
            ...prevState, Amount: prevState.Amount + costs["skirt"]["skirtItemsPipingList"]["Double-Piping-Neck"],
            pipingNeckCheck: false, pipingNeckSleeveCheck: false, pipingSideSlitCheck: false, pipingSleeveOnlyCheck:false, doublePipingNeckOnlyCheck:true
          }
          props.onSkirtDataChange(skirtDataindex, newItem);
          return newItem;
        });
      }
      else if (text === "Piping" && e.target.value === "Double Piping Sleeves Only") {
        setSkirtDataObj((prevState) => {
          let newItem = {
            ...prevState, Amount: prevState.Amount + costs["skirt"]["skirtItemsPipingList"]["Double-Piping-Sleeve"],
            pipingNeckCheck: false, pipingNeckSleeveCheck: false, pipingSideSlitCheck: false, pipingSleeveOnlyCheck:false, doublePipingNeckOnlyCheck:false,doublePipingSleevesOnlyCheck:true,doublePipingNeckandSleevesOnlyCheck:false,triplePipingSleevesOnlyCheck:false,triplePipingNeckOnlyCheck:false,triplePipingNeckandSleevesOnlyCheck:false
          }
          props.onSkirtDataChange(skirtDataindex, newItem);
          return newItem;
        });
      }
      else if (text === "Piping" && e.target.value === "Double Piping Neck and Sleeves Only") {
        setSkirtDataObj((prevState) => {
          let newItem = {
            ...prevState, Amount: prevState.Amount + costs["skirt"]["skirtItemsPipingList"]["Double-Piping-Neck-&-Sleeve"],
            pipingNeckCheck: false, pipingNeckSleeveCheck: false, pipingSideSlitCheck: false, pipingSleeveOnlyCheck:false, doublePipingNeckOnlyCheck:false,doublePipingSleevesOnlyCheck:false,doublePipingNeckandSleevesOnlyCheck:true,triplePipingSleevesOnlyCheck:false,triplePipingNeckOnlyCheck:false,triplePipingNeckandSleevesOnlyCheck:false
          }
          props.onSkirtDataChange(skirtDataindex, newItem);
          return newItem;
        });
      }
      else if (text === "Piping" && e.target.value === "Triple Piping Sleeves Only") {
        setSkirtDataObj((prevState) => {
          let newItem = {
            ...prevState, Amount: prevState.Amount + costs["skirt"]["skirtItemsPipingList"]["Triple-Piping-Sleeve"],
            pipingNeckCheck: false, pipingNeckSleeveCheck: false, pipingSideSlitCheck: false, pipingSleeveOnlyCheck:false, doublePipingNeckOnlyCheck:false,doublePipingSleevesOnlyCheck:false,doublePipingNeckandSleevesOnlyCheck:false,triplePipingSleevesOnlyCheck:true,triplePipingNeckOnlyCheck:false,triplePipingNeckandSleevesOnlyCheck:false
          }
          props.onSkirtDataChange(skirtDataindex, newItem);
          return newItem;
        });
      }
      else if (text === "Piping" && e.target.value === "Triple Piping Neck Only") {
        setSkirtDataObj((prevState) => {
          let newItem = {
            ...prevState, Amount: prevState.Amount + costs["skirt"]["skirtItemsPipingList"]["Triple-Piping-Neck"],
            pipingNeckCheck: false, pipingNeckSleeveCheck: false, pipingSideSlitCheck: false, pipingSleeveOnlyCheck:false, doublePipingNeckOnlyCheck:false,doublePipingSleevesOnlyCheck:false,doublePipingNeckandSleevesOnlyCheck:false,triplePipingSleevesOnlyCheck:false,triplePipingNeckOnlyCheck:true,triplePipingNeckandSleevesOnlyCheck:false
          }
          props.onSkirtDataChange(skirtDataindex, newItem);
          return newItem;
        });
      }
      else if (text === "Piping" && e.target.value === "Triple Piping Neck and Sleeves Only") {
        setSkirtDataObj((prevState) => {
          let newItem = {
            ...prevState, Amount: prevState.Amount + costs["skirt"]["skirtItemsPipingList"]["Triple-Piping-Neck-&-Sleeve"],
            pipingNeckCheck: false, pipingNeckSleeveCheck: false, pipingSideSlitCheck: false, pipingSleeveOnlyCheck:false, doublePipingNeckOnlyCheck:false,doublePipingSleevesOnlyCheck:false,doublePipingNeckandSleevesOnlyCheck:false,triplePipingSleevesOnlyCheck:false,triplePipingNeckOnlyCheck:false,triplePipingNeckandSleevesOnlyCheck:true
          }
          props.onSkirtDataChange(skirtDataindex, newItem);
          return newItem;
        });
      } 

      // Piping End
    }
    if (dataHeadName === "extraCharges") {
      let prevCharge = 0;
      if (skirtDataObj["Customization Charges"] !== undefined) {
        prevCharge = parseInt(skirtDataObj["Customization Charges"]);
      }
      if (isNaN(prevCharge)) {
        prevCharge = 0;
      }

      setSkirtDataObj((prevState) => {
        let newItem = {
          ...prevState, [text]: e.target.value,
          Amount: prevState.Amount - prevCharge + (e.target.value === "" ? 0 : parseInt(e.target.value)),
        }
        props.onSkirtDataChange(skirtDataindex, newItem);
        return newItem;
      });



    }
    if (dataHeadName === "extraSleeveCharges") {
      let prevChargeSleeve = 0;
      if (skirtDataObj["Sleeve Pattern Charge"] !== undefined) {
        prevChargeSleeve = parseInt(skirtDataObj["Sleeve Pattern Charge"]);
      }
      if (isNaN(prevChargeSleeve)) {
        prevChargeSleeve = 0;
      }

      setSkirtDataObj((prevState) => {
        let newItem = {
          ...prevState, [text]: e.target.value,
          Amount: prevState.Amount - prevChargeSleeve + (e.target.value === "" ? 0 : parseInt(e.target.value)),
        }
        props.onSkirtDataChange(skirtDataindex, newItem);
        return newItem;
      });
    }

    // skirt Amount Set
    skirtCostEstimateUpdate(orderID)
    skirtValuesChecker(orderID)

    const endTime = new Date().getTime();


    // await grantTotalUpdater()
  };


  const getskirtMeasurementDataForPerson = (orderID, selectedPersonValue, dress, text) => {
    
    var dataToSend = { user: "admin", mobNo: mobNo, personName: selectedPersonValue, };
    try {
      axios.post(Helpers().apiURL + "/getMeasurementDataForPerson", dataToSend)
        .then(function (response) {
          setSkirtDataObj((prevState) => {
            let newItem = {
              ...prevState, ...response.data.message.skirtData,
              [text]: selectedPersonValue,
              personName: selectedPersonValue,
              personNameDisabler: true,
              personNameVisibler: "none",
            }
            // sync with [arent array]
            props.onSkirtDataChange(skirtDataindex, newItem);

            return newItem;
          });
          skirtValuesChecker(orderID)
        });
    } catch (err) {
      alert("server down");
    }
  };

  const removeData = (dress, orderID, dataToRemove) => {
    for (let j in dataToRemove) {
      let tempKey = dataToRemove[j];
      delete skirtDataObj[tempKey];
    }
    setSkirtDataObj((prevState) => {
      let newItem = {
        ...prevState,
        Amount: costs["skirt"]["skirtItemsList"]["Basic"],
        skirtMeasurementCheck: true,
      }
      // sync with [parent array]
      props.onSkirtDataChange(skirtDataindex, newItem);
      return newItem;
    });
    skirtValuesChecker(orderID)
  };

  const skirtValuesChecker = (orderID) => {
    let tempData = skirtDataObjRef.current
    let tempDataKeys = Object.keys(tempData)


    if (skirtObjectKeysCheckList.every(elem => tempDataKeys.includes(elem))) {
      for (let k in skirtObjectKeysCheckList) {
        if (tempData[skirtObjectKeysCheckList[k]] === "") {
   
          setSkirtDataObj((prevState) => {
            let newItem = prevState.skirtMeasurementCheck ? { ...prevState, infoCompletionStatus: false } : prevState;
            // sync with [parent array]
            props.onSkirtDataChange(skirtDataindex, newItem);
            return newItem;
          });
          return
        }
      }
   
      setSkirtDataObj((prevState) => {
        let newItem = prevState.skirtMeasurementCheck ? { ...prevState, infoCompletionStatus: true } : prevState;
        // sync with [parent array]
        props.onSkirtDataChange(skirtDataindex, newItem);

        return newItem;
      });
    }
    else {
 
      setSkirtDataObj((prevState) => {
        let newItem = prevState.skirtMeasurementCheck ? { ...prevState, infoCompletionStatus: false } : prevState;
        // sync with [parent array]
        props.onSkirtDataChange(skirtDataindex, newItem);

        return newItem;
      });
    }
  }

  const reset = async (e, text, orderID, dress, index) => {
    let removeskirtData = [...skirtPatternStyle, ...skirtDesigningStyle, "Neck Pattern ID image", "Neck Pattern ID name", "Neck Pattern ID price", "Sleeve Pattern ID image", "Sleeve Pattern ID name", "SleevePatternCheck", "NeckPatternCheck", "Rope", "Zip", "With Elastic", "Dart", "Neck Type", "Piping", "Lining", "CostEstimateFinal", "NeckPatternPrevPrice", "liningCheck","boatNeckCheck","collarNeckCheck","parallelElasticBeltCheck","pipingNeckCheck","pipingNeckSleeveCheck", "pipingSideSlitCheck","Customization Charges", "Sleeve Pattern Charge"];
    for (let j in removeskirtData) {
      let tempKey = removeskirtData[j];
      delete skirtDataObj[tempKey];
    }

  
    setSkirtDataObj((prevState) => {
      let newItem = {
        ...prevState, Amount: costs["skirt"]["skirtItemsList"]["Basic"],
        skirtMeasurementCheck: true,
      };
      // sync with [parent array]
      props.onSkirtDataChange(skirtDataindex, newItem);

      return newItem;
    });
    skirtValuesChecker(orderID)

  };


  const uploadImage = async (e, text, orderID, dress, index) => {
    try {
      const file = e.target.files[0];
      let fileName = file.name;
      let trimFileName = fileName.substring(0, 2);
      if (trimFileName === "nP" && text === "Neck Pattern ID" && fileName.includes("-") === true) {
        let removeskirtData = [...skirtDesigningStyle, "Lining", "Piping", "pipingNeckCheck", "pipingNeckSleeveCheck", "Customization Charges", "Sleeve Pattern Charge", "CostEstimateFinal", "boatNeckCheck", "collarNeckCheck", "pipingSideSlitCheck", "parallelElasticBeltCheck", "liningCheck"];
        for (let j in removeskirtData) {
          let tempKey = removeskirtData[j];
          for (let k in skirtCheckBoxFields) {
            skirtDataObj[skirtCheckBoxFields[k]] = false;
          }
          delete skirtDataObj[tempKey];
        }

        setSkirtDataObj((prevState) => {
          let newItem = { ...prevState, };
          // sync with [parent array]
          props.onSkirtDataChange(skirtDataindex, newItem);

          return newItem;
        });

        // setskirtData(skirtData);
      } else if (
        trimFileName === "sP" &&
        text === "Sleeve Pattern ID" &&
        fileName.includes("-") === true
      ) {
      } else if (
        trimFileName === "nP" &&
        text === "Sleeve Pattern ID" &&
        (fileName.includes("-") === true || fileName.includes("-") === false)
      ) {
        sweetAlertShow("please upload valid Sleeve Pattern image", "warning");
        return;
      } else if (
        trimFileName === "sP" &&
        text === "Neck Pattern ID" &&
        (fileName.includes("-") === true || fileName.includes("-") === false)
      ) {
        sweetAlertShow("please upload valid Neck Pattern image", "warning");
        return;
      } else if (
        (trimFileName === "nP" || trimFileName !== "nP") &&
        text === "Neck Pattern ID"
      ) {
        sweetAlertShow("please upload valid Neck Pattern image", "warning");
        return;
      } else if (
        (trimFileName === "sP" || trimFileName !== "sP") &&
        text === "Sleeve Pattern ID"
      ) {
        sweetAlertShow("please upload valid Sleeve Pattern image", "warning");
        return;
      }


      var base64ImageCode = await base64Convertor(file);
      var fileSize = file.size;
      if (fileSize / 1024 <= 500) {
        var nameSS = file.name;
        var price = nameSS.replace(/\.[^/.]+$/, "").split("-")[1];
        if (text === "Sleeve Pattern ID" && dress === "skirt") {
          let SleevePatternCheck = false;
          try {
            SleevePatternCheck = skirtDataObj["SleevePatternCheck"];
            if (SleevePatternCheck === undefined || !SleevePatternCheck) {
              SleevePatternCheck = false;
            }
          } catch (err) {
            SleevePatternCheck = false;
          }


          if (SleevePatternCheck) {
            setSkirtDataObj((prevState) => {
              let newItem = { ...prevState, [text + " image"]: base64ImageCode, [text + " name"]: nameSS.replace(/\.[^/.]+$/, ""), SleevePatternCheck: true, };
              // sync with [parent array]
              props.onSkirtDataChange(skirtDataindex, newItem);
              return newItem;
            });
          }
          else {
            setSkirtDataObj((prevState) => {
              let newItem = { ...prevState, [text + " image"]: base64ImageCode, [text + " name"]: nameSS.replace(/\.[^/.]+$/, ""), SleevePatternCheck: true, };
              // sync with [parent array]
              props.onSkirtDataChange(skirtDataindex, newItem);
              return newItem;
            });
          }
        }

        else if (dress === "skirt" && text === "Neck Pattern ID") {
          let NeckPatternCheck = false;
          try {
            NeckPatternCheck = skirtDataObj["NeckPatternCheck"];
            if (NeckPatternCheck === undefined || !NeckPatternCheck) {
              NeckPatternCheck = false;
            }
          } catch (err) {
            NeckPatternCheck = false;
          }

          if (NeckPatternCheck) {
            setSkirtDataObj((prevState) => {
              let newItem = { ...prevState, [text + " image"]: base64ImageCode, [text + " name"]: nameSS.replace(/\.[^/.]+$/, ""), [text + " price"]: price, Amount: parseInt(price), NeckPatternCheck: true, NeckPatternPrevPrice: parseInt(price) };
              // sync with [parent array]
              props.onSkirtDataChange(skirtDataindex, newItem);

              return newItem;
            });
          }
          else {
            setSkirtDataObj((prevState) => {
              let newItem = { ...prevState, [text + " image"]: base64ImageCode, [text + " name"]: nameSS.replace(/\.[^/.]+$/, ""), [text + " price"]: price, Amount: parseInt(price), NeckPatternCheck: true, NeckPatternPrevPrice: parseInt(price) };

              // sync with [parent array]
              props.onSkirtDataChange(skirtDataindex, newItem);

              return newItem;
            });
          }
        }

      } else {
        sweetAlertShow(
          "File size is too large. Support File Size should be below 500KB",
          "warning"
        );
        return;
      }
      skirtValuesChecker(orderID)
    }
    catch (err) {
      console.log(err)
    }

  };


  const base64Convertor = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const openPops = (item, name) => {
    setopen(true);
    setskirtpatternview(item);
    setskirtpatterviewname(name);
  };

  const openskirtCaptureDialog = (skirtDataindex) => {
    setImageIndex(skirtDataindex)
    setskirtCaptureDialog(true);
  };
  const skirtDressImageDialog = (skirtDataindex) => {
    setImageIndex(skirtDataindex)
    setskirtDressImageDialogOpener(true);
  };

  const skirtStichDressImageDialog = (skirtDataindex) => {
    setImageIndex(skirtDataindex)
    setskirtStichDressImageDialogOpener(true);
  };
  const storeskirtImage = async (orderID, dressImage) => {
    let picBase64 = webcamRef.current.getScreenshot();
    picBase64 = await process_image(picBase64);
    if (picBase64 === null) {
      return
    }

    setSkirtDataObj((prevState) => {
      let newItem = {
        ...prevState, dressImage: picBase64,
        dressImageName: orderID + " Sample_IMG",
      };

      // sync with [parent array]
      props.onSkirtDataChange(skirtDataindex, newItem);

      return newItem;
    });

    setskirtCaptureDialog(false);
    skirtValuesChecker(orderID)
  };




  async function process_image(res, min_image_size = 50) {
    if (res) {
      const old_size = calc_image_size(res);
      if (old_size > min_image_size) {
        const resized = await reduce_image_file_size(res);
        const new_size = calc_image_size(resized);
        return resized;
      } else {
        return res;
      }
    } else {
      return null;
    }
  }

  async function reduce_image_file_size(base64Str, MAX_WIDTH = 450, MAX_HEIGHT = 450) {
    let resized_base64 = await new Promise((resolve) => {
      let img = new Image();
      img.src = base64Str;
      img.onload = () => {
        let canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL("image/jpeg", 0.7)); // this will return base64 image results after resize
      };
    });
    return resized_base64;
  }

  function calc_image_size(image) {
    let y = 1;
    if (image.endsWith("==")) {
      y = 2;
    }
    const x_size = image.length * (3 / 4) - y;
    return Math.round(x_size / 1024);
  }
  const skirtCostEstimateUpdate = (orderID) => {
    // let singleskirtData = skirtDataRef.current.find(el => el.skirtOrderId === props.skirtDataObj["skirtOrderId"])


    let singleskirtData = skirtDataObjRef.current
    var trueskirtValues = Object.keys(singleskirtData).filter((k) => singleskirtData[k] === true);
    let select = (trueskirtValues, skirtPricesToShow) =>
      trueskirtValues.reduce(
        (r, e) =>
          Object.assign(r, skirtPricesToShow[e] ? { [e]: skirtPricesToShow[e] } : null),
        {}
      );
    let output = select(trueskirtValues, skirtPricesToShow);
    let aoo = Object.values(output); //[{ Basic: 790}, { Pocket: 30}, {Zip: 40}, {With Elastic: 75}, {Piping-Only Neck: 160}]

    let totalObj = Object.assign({}, ...aoo); //{Basic: 790, Piping-Only Neck: 160,Pocket: 30,With Elastic: 75, Zip: 40}
    if (trueskirtValues.includes("NeckPatternCheck")) {
      delete totalObj["Basic"];
    }
    if ("Customization Charges" in singleskirtData) {
      if (singleskirtData["Customization Charges"] !== "") {
        totalObj["Customization Charges"] = singleskirtData["Customization Charges"];
      } else {
        delete totalObj["Customization Charges"];
      }
    }
    if ("Sleeve Pattern Charge" in singleskirtData) {
      if (singleskirtData["Sleeve Pattern Charge"] !== "") {
        totalObj["Sleeve Pattern Charge"] = singleskirtData["Sleeve Pattern Charge"];
      } else {
        delete totalObj["Sleeve Pattern Charge"];
      }
    }
    if ("Neck Pattern ID price" in singleskirtData) {
      if (singleskirtData["Neck Pattern ID price"] !== "") {
        totalObj["Neck Pattern ID price"] = singleskirtData["Neck Pattern ID price"];
      } else {
        delete totalObj["Neck Pattern ID price"];
      }
    }

    let costEstimateText = (skirtPricesToShow) =>
      Object.entries(skirtPricesToShow)
        .map(([k, v]) => `${k}: ${v}`)
        .join(", ");
    let tempCostEstimate = costEstimateText(totalObj).replaceAll(",", " | ");




    setSkirtDataObj((prevState) => {
      let newItem = prevState.skirtMeasurementCheck ? { ...prevState, CostEstimateFinal: tempCostEstimate } : prevState;
      // sync with [parent array]
      props.onSkirtDataChange(skirtDataindex, newItem);
      return newItem;
    });

  }

  // Print Measurements
  const printOrderData = (data) => {
    var doc = new jsPDF();
    doc.setFont(undefined, "bold").setFontSize(15).text("Dress ID - " + data["skirtOrderId"], 85, 10);

    doc.setFont(undefined, "bold").setFontSize(14).text("Measurements ", 15, 20);

    doc.autoTable({
      theme: 'grid',
      startY: 25,
      pageBreak: 'avoid',
      margin: { top: 1 },
      head: [["Shoulder Size", "Shoulder Width", "Breast Circum", "Breast Size", "Hip"]],
      body: [[data["Shoulder Size"], data["Shoulder Width"], data["Breast Circum"], data["Breast Size"], data["Hip"]]],
    } ,
    );

    doc.autoTable({
      theme: 'grid',
      startY: 45,
      pageBreak: 'avoid',
      margin: { top: 1 },
      head: [["Waist", "Neck F", "Neck B", "Full Length", "Side Slit"]],
      body: [[data["Waist"], data["Neck F"], data["Neck B"], data["Full Length"], data["Side Slit"]]],
    });

    doc.autoTable({
      theme: 'grid',
      startY: 65,
      pageBreak: 'avoid',
      margin: { top: 1 },
      head: [["Arm Hole", "Arm Length", "Arm Circum", "Ankle", "Skirt Length"]],
      body: [[data["Arm Hole"], data["Arm Length"], data["Arm Circum"], data["Ankle"], data["Skirt Length"]]],
    });

    doc.setFont(undefined, "bold").setFontSize(14).text("Tuck Style", 15, 90);
    doc.autoTable({
      theme: 'grid',
      startY: 95,
      pageBreak: 'avoid',
      margin: { top: 1 },
      head: [["Tuck Point", "Tuck Side"]],
      body: [[data["Tuck Point"], data["Tuck Side"]]],
    });

    doc.setFont(undefined, "bold").setFontSize(14).text("Design Selection", 15, 120);
    doc.autoTable({
      theme: 'grid',
      startY: 125,
      pageBreak: 'avoid',
      margin: { top: 1 },
      head: [[ "Rope", "Zip", "With Elastic"]],
      body: [[ data["Rope"] === true ? "Yes" : "No", data["Zip"] === true ? "Yes" : "No", data["With Elastic"] === true ? "Yes" : "No"]],
    });

    doc.setFont(undefined, "bold").setFontSize(14).text("Designing Style", 15, 150);
    doc.autoTable({
      theme: 'grid',
      startY: 155,
      pageBreak: 'avoid',
      margin: { top: 1 },
      head: [["Dart", "Neck Type", "Piping", "Lining"]],
      body: [[data["Dart"], data["Neck Type"], data["Piping"], data["Lining"]]],
    });

    doc.setFont(undefined, "bold").setFontSize(14).text("Remarks", 15, 180);
    doc.autoTable({
      theme: 'grid',
      startY: 185,
      pageBreak: 'avoid',
      margin: { top: 1 },
      head: [["Remarks"]],
      body: [[data["Remarks"]]],
    });


    doc.autoPrint();
    doc.output('dataurlnewwindow');
    
  };

 
 

  // useEffect(() => {
  //   console.log("useEffect Called")
  //   skirtValuesChecker("orderID")
  // }, [])
 

  const switchCam = () => {
    const newFcMode = facingMode === "user" ? { exact: "environment" } : "user";
    setFacingMode(newFcMode);
  };
  const handleUserMedia = () => setTimeout(() => setCameraLoading(false), 1_000);
  const webcamRef = useRef(null);

  const classes = useStyles();

  return (
    <>
      <div style={{ padding: "1%" }}>
        <Card elevation={10} className={classes.sbCardheight} style={{ backgroundColor: skirtLightColorCode }}  >
          <div>
            <Card style={{ backgroundColor: skirtColorCode }}>
              <div style={{ display: "flex", height: 25 }}>
                <div style={{ margin: "auto" }}>
                  <Typography className={classes.dressBlockTitleText}>
                    SKIRT
                  </Typography>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Tooltip title="Print Measurement" arrow>
                    <IconButton onClick={() => { printOrderData(skirtDataObj) }} aria-label="print" >
                      <PrintIcon style={{ color: "white" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={skirtDataObj["infoCompletionStatus"] ? skirtDataObj["skirtOrderId"] + " Order Filled" : skirtDataObj["skirtOrderId"] + " Order Not Filled"} aria-label="add" arrow>
                    <Avatar style={{ backgroundColor: skirtDataObj["infoCompletionStatus"] ? "#49FF00" : "yellow", width: 20, height: 15, marginRight: 10 }}>
                      <FiberManualRecordIcon style={{ color: skirtDataObjRef.current["infoCompletionStatus"] ? "#49FF00" : "yellow" }} />
                    </Avatar>
                  </Tooltip>
                  <Button
                    className={classes.dressBlockCloseBtn}
                    onClick={() => { onSkirtBtnClickClose(skirtDataObj["skirtOrderId"]) }}
                  >
                    X
                  </Button>
                </div>

              </div>
            </Card>

            <Card style={{ display: "flex", backgroundColor: skirtLightColorCode, borderRadius: 0 }}  >
              <div style={{ display: "flex", paddingTop: "10px", paddingLeft: "2%", backgroundColor: skirtColorCode, paddingRight: "5%", borderTopRightRadius: "50%", borderBottomRightRadius: "50%" }}>
                <div>
                  <Typography className={classes.dressBlockIDText} >
                    ID - {skirtDataObj["skirtOrderId"]}
                  </Typography>
                </div>
              </div>
              <div className={classes.personNameContainer} >
                <TextField
                  InputProps={{ className: classes.topBarText }}
                  disabled={textBoxDisabler ? textBoxDisabler : skirtDataObj["personNameDisabler"]}
                  value={skirtDataObj["personName"]}
                  variant="outlined"
                  size="small"
                  label="Person Name"
                  style={{ minWidth: "235px", margin: "5px 5px", display: skirtDataObj["personNameVisibler"], }}
                  className={classes.textField}
                  onChange={(e) => { onMeasurementValueSet(e, "personName", skirtDataObj["skirtOrderId"], "personName", skirtDataindex); }}
                />

                <FormControl
                  size="small"
                  className={classes.textFieldCD}
                  variant="outlined"
                  disabled={mode === "view" ? textBoxDisabler : skirtDataObj["personNameSelectorDisabler"]}
                  style={{ minWidth: "235px", margin: "5px 5px" }}
                  className={classes.textField}
                >
                  <InputLabel>Previous Name List</InputLabel>

                  <Select style={{ height: 35 }}
                    onChange={(e) => { onMeasurementValueSet(e, "personNameSelected", skirtDataObj["skirtOrderId"], "personNameSelected", skirtDataindex); }}
                    value={skirtDataObj["personNameSelected"] === undefined ? "" : skirtDataObj["personNameSelected"]}
                    label="Previous Name List"
                  >
                    <MenuItem value="None">
                      <Typography className={classes.selectText}>None</Typography>
                    </MenuItem>

                    {(skirtPrevRegNames ===
                      undefined
                      ? []
                      : skirtPrevRegNames
                    ).map((option, index) => (
                      <MenuItem key={option} value={option}>
                        <Typography className={classes.selectText}>
                          {option}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <ToggleButtonGroup size="small" value={skirtDataObj["itemDeliverStatus"]} style={{ height: 35, margin: "5px 5px" }} exclusive onChange={(e) => { onMeasurementValueSet(e, "text", skirtDataObj["skirtOrderId"], "deliverSelection", skirtDataindex); }} >
                  <ToggleButton size="small" value="Not Delivered" style={{ fontSize: 12, fontFamily: Fonts.UBUNTU }}  >
                    <FontAwesomeIcon style={{ marginRight: 10 }} icon={faSpinner} />
                    Not Delivered
                  </ToggleButton>
                  <ToggleButton size="small" value="Delivered" style={{ fontSize: 12, fontFamily: Fonts.UBUNTU, backgroundColor: skirtDataObj["itemDeliverStatus"] === "Delivered" ? Colors.SKIRT_COLOR : "", color: skirtDataObj["itemDeliverStatus"] === "Delivered" ? "white" : "" }}>
                    <FontAwesomeIcon style={{ marginRight: 10 }} icon={faCheckDouble} />
                    Delivered
                  </ToggleButton>
                </ToggleButtonGroup>

              </div>
            </Card>




            <Card elevation={5} style={{ padding: 15, marginLeft: 10, marginRight: 10, marginTop: 10, display: "flex", flexWrap: "wrap", }}>
              <Box style={{ marginRight: 10, borderTopLeftRadius: "10px", borderTopRightRadius: "10px", }}  >
                <div>
                  <div style={{ display: "flex", backgroundColor: skirtColorCode, borderTopLeftRadius: "8px", borderTopRightRadius: "8px", }}>
                    <Typography className={classes.dressBlockSubHeadText} >
                      Measurements
                    </Typography>
                  </div>
                  <div style={{ display: "flex", flexWrap: "wrap", border: "2px solid ", borderColor: skirtColorCode, }}>
                    {skirtTextFields.map(
                      (text, textFieldIndex) => (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div>
                            <Box style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", display: "flex", margin: "10px" }}>
                              <Typography noWrap className={classes.skirtMeasurementHeadText}>
                                {text}
                              </Typography>

                              <TextField
                                className={classes.skirtMeasurementTextField}
                                value={skirtDataObj[text] === undefined ? "" : skirtDataObj[text]}
                                inputProps={{ readonly: mode === "view" ? "" : false }}
                                onChange={(e) => { onMeasurementValueSet(e, text, skirtDataObj["skirtOrderId"], "measurements", skirtDataindex); }}
                                style={{ width: bigSkirtMeasurements.includes(text) ? 200 : 120 }}
                                size="small"
                                variant="outlined"
                                type="text"
                                fullWidth
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      inch
                                    </InputAdornment>
                                  ),
                                }}
                              ></TextField>
                            </Box>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </Box>

              <Box style={{ marginTop: 10, marginRight: 10, borderTopLeftRadius: "10px", borderTopRightRadius: "10px", }}>
                <div>
                  <div style={{ display: "flex", backgroundColor: skirtColorCode, borderTopLeftRadius: "5px", borderTopRightRadius: "5px", }}>
                    <Typography className={classes.dressBlockSubHeadText}>
                      Tuck Style
                    </Typography>
                  </div>
                  <div style={{ display: "flex", flexWrap: "wrap", border: "2px solid ", borderColor: skirtColorCode, }}>
                    {skirtTuckStyle.map((text, index) => (
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                        <div>
                          <TextField
                            type="number"
                            size="small"
                            onInput={(e) => e.target.value = e.target.value.slice(0, 4)}
                            className={classes.textField}
                            value={skirtDataObj[text]}
                            style={{ margin: "3%" }}
                            disabled={textBoxDisabler}
                            onChange={(e) => { onMeasurementValueSet(e, text, skirtDataObj["skirtOrderId"], "measurements") }}
                            label={text}
                            variant="outlined"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Box>

              <Box style={{ marginTop: 10, marginRight: 10, borderTopLeftRadius: "10px", borderTopRightRadius: "10px", }}>
                <div>
                  <div style={{ display: "flex", backgroundColor: skirtColorCode, borderTopLeftRadius: "5px", borderTopRightRadius: "5px", }}>
                    <Typography className={classes.dressBlockSubHeadText} >
                      Patterns
                    </Typography>
                    <Button size="small" style={{ display: mode === "view" ? "none" : "" }} variant="contained" color="secondary" startIcon={<AutorenewIcon />} onClick={(e) => { reset(e, "text", skirtDataObj["skirtOrderId"], "skirt", skirtDataindex); }}                                >
                      Reset
                    </Button>
                  </div>
                  <div style={{ display: "flex", flexWrap: "wrap", border: "2px solid ", borderColor: skirtColorCode, }}>
                    {skirtPatternStyle.map((text, index) => (
                      <div style={{ display: "flex", alignItems: "center", }} >
                        <TextField
                          disabled={textBoxDisabler}
                          size="small"
                          className={classes.textField}
                          label={text}
                          value={skirtDataObj[text + " name"] || ""}
                          inputProps={{ maxLength: 0 }}
                          style={{ margin: "2%" }}
                          variant="outlined"
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <label>
                                <Input
                                  accept="image/*"
                                  id="contained-button-file"
                                  multiple
                                  type="file"
                                  onChange={(e) => { uploadImage(e, text, skirtDataObj["skirtOrderId"], "skirt", skirtDataindex); }}
                                />
                                <Button
                                  variant="contained"
                                  component="span"
                                  style={{ paddingTop: "7px", paddingBottom: "7px", paddingRight: "20px", paddingLeft: "20px", marginRight: "-13px", background: skirtColorCode, color: "white", display: mode === "view" ? "none" : "block", }}
                                >
                                  Browse
                                </Button>
                              </label>
                            ),
                          }}
                        />
                        <img
                          onClick={() => openPops(skirtDataObj[text + " image"] || defaultImage, skirtDataObj[text + " name"] || '')} 
                          src={skirtDataObj[text + " image"] || defaultImage}
                          style={{ width: 60, height: 60, marginRight: 10, }}
                          className={classes.imagePreview}
                          alt="img"
                        ></img>

                        <div>
                          <Dialog open={open} onClose={() => { setopen(false); }} >
                            <DialogTitle>{skirtpatterviewname}</DialogTitle>
                            <div style={{ display: "flex", justifyContent: "center", width: "100%", flexDirection: "column" }} >
                              <img style={{ width: 500, height: 500, marginRight: 10 }} src={skirtpatternview} alt="img" >
                              </img>
                            </div>
                          </Dialog>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Box>

              <Box style={{ marginTop: 10, marginRight: 10, borderTopLeftRadius: "10px", borderTopRightRadius: "10px", }}>
                <div style={{ display: "flex", backgroundColor: skirtColorCode, borderTopLeftRadius: "5px", borderTopRightRadius: "5px", }}>
                  <Typography className={classes.dressBlockSubHeadText}>
                    Design Selection
                  </Typography>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap", border: "2px solid ", borderColor: skirtColorCode, }} >
                  {skirtCheckBoxFields.map((text, index) => (
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                      <div style={{ marginTop: "5%", marginLeft: "5%", marginBottom: "5%", }}>
                        <Checkbox
                          checked={skirtDataObj[text] ? true : false}
                          disabled={textBoxDisabler}
                          onChange={(e) => { onMeasurementValueSet(e, text, skirtDataObj["skirtOrderId"], "designSelection", skirtDataindex); }}
                          inputProps={{ "aria-label": "secondary checkbox", }}
                          style={{ transform: "scale(1)", color: skirtColorCode, }}
                        />
                      </div>
                      <div >
                        <Typography style={{ marginRight: 30 }} noWrap={true}>
                          {text}
                        </Typography>
                      </div>
                    </div>
                  ))}
                </div>
              </Box>

              <Box style={{ marginTop: 10, marginRight: 10, borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }} >
                <div style={{ display: "flex", backgroundColor: skirtColorCode, borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}  >
                  <Typography className={classes.dressBlockSubHeadText}  >
                    Designing Style
                  </Typography>
                  {/* <Button variant="contained" color="secondary" startIcon={<AutorenewIcon />}>Reset</Button> */}
                </div>
                <div style={{ display: "flex", flexWrap: "wrap", border: "2px solid ", borderColor: skirtColorCode }}   >
                  {skirtDesigningStyle.map(
                    (dropDownText, index) => (

                      <div style={{ margin: 10, }}>
                        <FormControl variant="outlined" size="small" style={{ width: "200px"}} className={classes.textField} >
                          <InputLabel>{dropDownText}</InputLabel>
                          <Select
                            value={skirtDataObj[dropDownText] === undefined ? "" : skirtDataObj[dropDownText]}
                            disabled={textBoxDisabler}
                            label={dropDownText}
                            onChange={(e) => { onMeasurementValueSet(e, dropDownText, skirtDataObj["skirtOrderId"], "designStyle", skirtDataindex); }}
                            inputProps={{ "aria-label": "secondary checkbox", }}
                            style={{ transform: "scale(1)"}} 
                          >
                           {dropDownText === "Dart"
                              ? skirtDartDropDownData.map(
                                (text, index) => (
                                  <MenuItem value={text}>
                                    {text}
                                  </MenuItem>
                                )
                              )
                              : dropDownText === "Neck Type"
                                ? skirtNeckTypeDropDownData.map(
                                  (text, index) => (
                                    <MenuItem value={text}>
                                      {text}
                                    </MenuItem>
                                  )
                                )
                                // : dropDownText === "Pant Style"
                                //   ? pantStyleDropDownData.map(
                                //     (text, index) => (
                                //       <MenuItem value={text}>
                                //         {text}
                                //       </MenuItem>
                                //     )
                                //   )
                                  : dropDownText === "Piping"
                                    ? skirtPipingDropDownData.map(
                                      (text, index) => (
                                        <MenuItem value={text}>
                                          {text}
                                        </MenuItem>
                                      )
                                    )
                                    : skirtLiningDropDownData.map(
                                      (text, index) => (
                                        <MenuItem value={text}>
                                          {text}
                                        </MenuItem>
                                      )
                                    )}
                          </Select>
                        </FormControl>
                      </div>

                    )
                  )}
                </div>
              </Box>


              <Box style={{ marginTop: 10, marginRight: 10, borderTopLeftRadius: "10px", borderTopRightRadius: "10px", display: designTeamContentHider, }} >
                <div>
                  <div style={{ display: "flex", backgroundColor: skirtColorCode, borderTopLeftRadius: "5px", borderTopRightRadius: "5px", }}>
                    <Typography className={classes.dressBlockSubHeadText} >
                      Extra Charges
                    </Typography>
                    {/* <Button variant="contained" color="secondary" startIcon={<AutorenewIcon />}>Reset</Button> */}
                  </div>
                  <div style={{ display: "flex", flexWrap: "wrap", border: "2px solid ", borderColor: skirtColorCode, }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", }}  >
                      <TextField
                        size="small"
                        type="number"
                        onInput={(e) => e.target.value = e.target.value.slice(0, 6)}
                        className={classes.textField}
                        value={skirtDataObj["Customization Charges"] === undefined ? "" : skirtDataObj["Customization Charges"]}
                        disabled={textBoxDisabler}
                        onChange={(e) => { onMeasurementValueSet(e, "Customization Charges", skirtDataObj["skirtOrderId"], "extraCharges", skirtDataindex); }}
                        label={"Customization Charges"}
                        style={{ margin: "4.5%" }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" style={{ marginLeft: 10 }}>
                              ₹
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                      <TextField
                        size="small"
                        type="number"
                        className={classes.textField}
                        onInput={(e) => e.target.value = e.target.value.slice(0, 6)}
                        value={skirtDataObj["Sleeve Pattern Charge"] === undefined ? "" : skirtDataObj["Sleeve Pattern Charge"]}
                        disabled={textBoxDisabler}
                        onChange={(e) => { onMeasurementValueSet(e, "Sleeve Pattern Charge", skirtDataObj["skirtOrderId"], "extraSleeveCharges", skirtDataindex) }}
                        label="Sleeve Pattern Charge"
                        style={{ margin: "4.5%" }}
                        InputProps={{ startAdornment: (<InputAdornment position="start" style={{ marginLeft: 10 }}> ₹  </InputAdornment>), }}
                        variant="outlined"
                      />
                    </div>
                  </div>
                </div>
              </Box>


              <Box style={{ marginTop: 10, marginRight: 10, borderTopLeftRadius: "10px", borderTopRightRadius: "10px", }}>
                <div>
                  <div style={{ display: "flex", backgroundColor: skirtColorCode, borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                    <Typography className={classes.dressBlockSubHeadText}>
                      Remarks
                    </Typography>
                  </div>
                  <div style={{ display: "flex", flexWrap: "wrap", border: "2px solid ", borderColor: skirtColorCode }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", border: "2px solid white" }}>
                      <TextField
                        size="small"
                        value={skirtDataObj["Remarks"]}
                        disabled={textBoxDisabler}
                        onChange={(e) => { onMeasurementValueSet(e, "Remarks", skirtDataObj["skirtOrderId"], "measurements", skirtDataindex) }}
                        label={"Remarks"}
                        style={{ margin: "2.3%", width: "350px" }}
                        className={classes.textField}
                        inputProps={{ maxLength: 150 }}
                        variant="outlined"
                      />
                    </div>
                  </div>
                </div>
              </Box>

              <Box style={{ marginTop: 10, marginRight: 10, borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }} >
                <div>
                  <div style={{ display: "flex", backgroundColor: skirtColorCode, borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                    <Typography className={classes.dressBlockSubHeadText}>
                      Dress
                    </Typography>
                  </div>
                  <div style={{ display: "flex", flexWrap: "wrap", border: "2px solid ", borderColor: skirtColorCode, }} >
                    <div style={{ display: "flex", flexDirection: "row" }} >
                      <TextField
                        size="small"
                        disabled={textBoxDisabler}
                        label={"Dress Image"}
                        value={skirtDataObj["dressImageName"] || ""}
                        inputProps={{ maxLength: 2 }}
                        style={{ marginTop: "2%", marginBottom: "2%", marginLeft: "2%", }}
                        className={classes.textField}
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <Button
                              style={{ paddingTop: "7px", paddingBottom: "7px", paddingRight: "30px", paddingLeft: "20px", marginRight: "-13px", background: skirtColorCode, color: "white", display: mode === "view" ? "none" : "block", }}
                              onClick={() => { openskirtCaptureDialog(skirtDataindex) }}
                            >
                              CAPTURE
                            </Button>
                          ),
                        }}
                      />
                      <img
                        onClick={() => { skirtDressImageDialog(skirtDataindex) }}
                        alt="skirt dress"
                        className={classes.imagePreview}
                        style={{ width: 60, height: 60, marginLeft: 10, marginRight: 10, }}
                        src={skirtDataObj["dressImage"] || defaultImage}
                      />
                      <div>
                        <Dialog open={skirtDressImageDialogOpener} onClose={() => { setskirtDressImageDialogOpener(false) }}>
                          <DialogTitle>{skirtData[imageIndex] === undefined ? 'No Image' : skirtData[imageIndex]["dressImageName"]}</DialogTitle>
                          <div style={{ display: "flex", justifyContent: "center", width: "100%", flexDirection: "column", }} >
                            <img alt="skirt dress" style={{ width: 600, height: 600 }} src={skirtData[imageIndex] === undefined ? defaultImage : skirtData[imageIndex]["dressImage"]} />
                          </div>
                        </Dialog>
                      </div>



                      <Dialog open={skirtCaptureDialog} onClose={() => { setskirtCaptureDialog(false) }}>
                        <div style={{
                          display: 'flex', backgroundColor: skirtColorCode, color: "white", height: '50px', justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                          <DialogTitle style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                            Capture your skirt Image
                          </DialogTitle>
                          <div style={{ backgroundColor: 'white', display: 'flex', alignItems: 'center', }}>
                            <Tooltip title="Switch Camera">
                              <IconButton onClick={() => switchCam()} aria-label="cameraSwitch">
                                <CachedIcon style={{ color: skirtColorCode }} />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>

                        <div style={{ display: "flex", justifyContent: "center", width: "100%", flexDirection: "column" }} >
                          {cameraLoading && <Typography style={{ textAlign: 'center' }} > Camera Loading... </Typography>}
                          <Webcam
                            videoConstraints={{ facingMode: facingMode }}
                            style={{ width: "100%", height: "35%", opacity: cameraLoading ? 0 : 1 }}
                            screenshotFormat="image/png"
                            ref={webcamRef}
                            onUserMedia={handleUserMedia} />

                          <Button
                            style={{ backgroundColor: skirtColorCode, color: "white", fontWeight: "bold", }}
                            disabled={textBoxDisabler}
                            variant="contained"
                            onClick={() => { storeskirtImage(skirtData[imageIndex]["skirtOrderId"], "skirtDressImage", imageIndex) }}
                          >
                            Capture skirt Image
                          </Button>
                        </div>
                      </Dialog>
                    </div>
                  </div>
                  <div>

                  </div>
                </div>
              </Box>

              <Box style={{ marginTop: 10, marginRight: 10, borderTopLeftRadius: "10px", borderTopRightRadius: "10px", }}  >
                <div>
                  <div style={{ display: "flex", backgroundColor: skirtColorCode, borderTopLeftRadius: "8px", borderTopRightRadius: "8px", }}>
                    <Typography className={classes.dressBlockSubHeadText} >
                      Workers
                    </Typography>
                  </div>
                  <div style={{ display: "flex", flexWrap: "wrap", border: "2px solid ", borderColor: skirtColorCode,}}>
                    {skirtWorker.map(
                      (text, textFieldIndex) => (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div>
                            <Box style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", display: "flex", margin: "10px" }}>
                              <Typography noWrap className={classes.skirtMeasurementHeadText}>
                                {text.name}
                              </Typography>

                              <TextField
                                className={classes.skirtMeasurementTextField}
                                value={skirtDataObj[text.dbvalue] === undefined ? "" : skirtDataObj[text.dbvalue]}
                                inputProps={{ readonly: ""}}
                                style={{ width: 200 }}
                                size="small"
                                variant="outlined"
                                type="text"
                                fullWidth

                              ></TextField>
                            </Box>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </Box>

              <Box style={{ marginTop: 10, marginRight: 10, borderTopLeftRadius: "10px", borderTopRightRadius: "10px", display: (mode === "edit" || mode === "add") ? "none" : "" }} >
                <div>
                  <div style={{ display: "flex", backgroundColor: skirtColorCode, borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                    <Typography className={classes.dressBlockSubHeadText}>
                      Stiched Dress
                    </Typography>
                  </div>
                  <div style={{ display: "flex", flexWrap: "wrap", border: "2px solid ", borderColor: skirtColorCode, }}>
                    <div style={{ display: "flex", flexDirection: "row", }} >
                      <TextField size="small"
                        disabled={textBoxDisabler}
                        label={"Dress Image"}
                        value={skirtDataObj["stichedDressImageName"] || ""}
                        inputProps={{ maxLength: 2 }}
                        style={{ marginTop: "2%", marginBottom: "2%", marginLeft: "2%" }}
                        variant="outlined"

                      />
                      <img
                        onClick={() => { skirtStichDressImageDialog(skirtDataindex) }}
                        alt="skirt dress"
                        className={classes.imagePreview}
                        style={{ width: 60, height: 60, marginLeft: 10, marginRight: 10, }}
                        src={skirtDataObj["stichedDressImage"] || defaultImage}
                      />

                      <div>
                        <Dialog open={skirtStichDressImageDialogOpener} onClose={() => { setskirtStichDressImageDialogOpener(false) }}>
                          <DialogTitle>{skirtData[imageIndex] === undefined ? 'No Image' : skirtData[imageIndex]["stichedDressImageName"]}</DialogTitle>
                          <div style={{ display: "flex", justifyContent: "center", width: "100%", flexDirection: "column", }} >
                            <img alt="skirt dress" style={{ width: 600, height: 600 }} src={skirtData[imageIndex] === undefined ? defaultImage : skirtData[imageIndex]["stichedDressImage"]} />
                          </div>
                        </Dialog>
                      </div>
                    </div>
                  </div>

                </div>
              </Box>

            </Card>
            <Card
              elevation={5}
              style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10, display: designTeamContentHider, justifyContent: "center", backgroundColor: skirtLightColorCode }}
            >
              <div style={{ flex: 1 }}>
                <Typography
                  style={{ display: "flex", flexDirection: "row", marginLeft: 30, fontWeight: "bold" }}
                  variant="subtitle1"
                >
                  Cost Estimate
                </Typography>
              </div>
              <div style={{ justifyContent: "flex-end", alignItems: "flex-end", marginRight: 30, }}  >
                <Typography
                  style={{ color: Colors.SKIRT_COLOR }}
                  variant="subtitle2"
                >
                  {skirtDataObj["CostEstimateFinal"]}
                </Typography>
              </div>
            </Card>

            <Card elevation={5} style={{ display: designTeamContentHider, backgroundColor: skirtColorCode }}>
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ flex: 1 }}>
                  <Typography style={{ fontWeight: "bold", color: "white", paddingLeft: 10 }} variant="h6"  >
                    Amount
                  </Typography>
                </div>
                <div style={{ justifyContent: "flex-end", border: "2px solid ", borderColor: skirtColorCode, alignItems: "flex-end", backgroundColor: "white" }} >
                  <Typography style={{ fontWeight: "bold", color: "green", textAlign: "right", width: 200, marginRight: 10 }} variant="h5" >
                    ₹ {skirtDataObj["Amount"]}
                  </Typography>
                </div>
              </div>

            </Card>
          </div>
        </Card>
      </div>
    </>

  );
}
export default memo(SkirtDressComponent)